<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex justify-center py-3 logo-container w-full">
      <img
        :src="require('../../../../../assets/images/fcLOgo.svg')"
        alt="Fresh Clinics"
        width="251"
        height="56"
      />
    </div>
    <div class="w-11/12 mx-auto my-8 px-8 pt-8 pb-16 rounded-lg bg-login login-tabs-container">
      <div class="form-heading">
        <p>Welcome to Fresh Clinics! 👋🏻</p>
      </div>
      <login-jwt></login-jwt>
    </div>
  </div>
</template>


<script>
import LoginJwt from "./LoginJWT.vue";

export default {
  components: {
    LoginJwt,
  },
};
</script>

<style lang="scss">
.login-tabs-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  max-width: 26rem;

  .con-tab {
    padding-bottom: 14px;
  }
  .con-slot-tabs {
    margin-top: 1rem;
  }
}

.form-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #0e4333;
}

.logo-container {
  border-bottom: 2px solid #ebe9f1;
}
</style>
